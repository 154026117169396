import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import locationimg from '../assets/images/location-img.jpg'
import { LazyLoadImage } from 'react-lazy-load-image-component'
// import commercialGates from '../assets/images/commercial-gates.jpg'

class Location extends React.Component {
  render() {
    const siteTitle =
      'J&J Gates Locations in Arlington, TX and Dallas-Fort Worth, TX'
    const siteDescription =
      'J&J Gates Service & Design has Office Locations in Fort Worth, Dallas, Arlington, and Burleson, TX! Call  for Info on Our Custom Gates'

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Our Locations" description={siteDescription} />

        <div className="container-fluid text-center headline gates-headline">
          <div className="container">
            <h1 className="text-white m-0">Our Locations</h1>
          </div>
        </div>

        <div className="container gates-container">
          <div className="row gates-row2">
            <div className="col-md-2 mb-5">
              <LazyLoadImage
                className="w-100"
                src={locationimg}
                alt="location"
              />
            </div>
            <div className="col-md-10 text-justify">
              <p>
                When you need quality gate services in the DFW metroplex, J & J
                Gates Service and Design is just a phone call away! We have four
                locations throughout the area to best serve you, and you can
                count on friendly, professional service all over the DFW area.
                Whether you need commercial or residential gate installation and
                repair, call our main office number at our location nearest to
                you. We are happy to answer your questions or provide a quote
                for your gate project. We look forward to helping you find the
                perfect gate for your property!
              </p>
            </div>
          </div>

          <div className="gate-types">
            <div className="row gates-row3 text-center">
              <div className="col-md-3">
                <div className="location">
                  <Link to="/locations/dallas-tx">
                    <h5>Dallas Location</h5>
                  </Link>
                  <p>325 North St. Paul Street #3100 Dallas, TX 75201</p>
                  <a href="tel:8174662794">(817) 466-2794</a>
                </div>
              </div>
              <div className="col-md-3">
                <div className="location">
                  <Link to="/locations/arlington-tx">
                    <h5>Arlington Location</h5>
                  </Link>
                  <p>2000 Camden Ct. Arlington, TX 76013</p>
                  <a href="tel:8174662794">(817) 466-2794</a>
                </div>
              </div>
              <div className="col-md-3">
                <div className="location">
                  <Link to="/locations/fort-worth-tx">
                    <h5>Fort Worth Location</h5>
                  </Link>
                  <p>201 Main St., 6th Floor Fort Worth, TX 76102</p>
                  <a href="tel:8174662794">(817) 466-2794</a>
                </div>
              </div>
              <div className="col-md-3">
                <div className="location">
                  <Link to="/locations/burleson-tx">
                    <h5>Burleson Location</h5>
                  </Link>
                  <p>12308 Rendon Rd Ste. 108 Burleson, Tx. 76028</p>
                  <a href="tel:8174662794">(817) 466-2794</a>
                </div>
              </div>
            </div>
          </div>

          <div className="gates-row5 d-padding-t set-width text-center mx-auto">
            <h4 className="mt-0">Reliable Gate Services in DFW</h4>
            <hr />
            <div className="col-md-12 ">
              <p className="mt-2 ">
                Looking for a wrought iron automatic gate for your private
                residence? Need a high-security gate for your commercial
                property? At J & J Gates Service and Design, we do it all. Our
                experienced gate technicians repair and install all types of
                gate systems, from decorative gates to special access systems.
                We also repair a wide variety of gate systems, including those
                we did not install ourselves! When you need help with your gate,
                make us your first choice. No matter what style or type of gate
                you are looking for, our professionals will help you find the
                perfect gate system for your needs and budget.{' '}
                <strong>
                  Call J & J Gates Service and Design at for top quality gate
                  system installation in the Dallas-Fort Worth area.
                </strong>
              </p>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Location

export const LocationQuery = graphql`
  query LocationQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
